import * as React from "react";
import MainContentStructure from "../components/NavigationBar/MainContentStructure";
import FeedSection from '../components/Home/FeedSection';

export default function Home(props) {
    return (
        <MainContentStructure>
            <FeedSection />
        </MainContentStructure>
    )
}